import { PropsWithChildren, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

function OverlayPortal({ children }: PropsWithChildren<{}>) {
    const overlayPortalElement = useRef(document.createElement('div')).current;
    overlayPortalElement.className = 'overlay-portal';

    useEffect(() => {
        document.body.appendChild(overlayPortalElement);

        return () => {
            document.body.removeChild(overlayPortalElement);
        };
    }, []);

    return ReactDOM.createPortal(children, overlayPortalElement);
}

export default OverlayPortal;
